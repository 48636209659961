.nav-background {
  background-color: black;
  background-image: url('./jakspot.jpg');
  box-shadow: inset 0 0 0 2000px rgba(29, 161, 242, 0.2);
  border-bottom: 1.5px solid white;
}

@media screen and (max-width: 800px) {
  .nav-background {
    background-color: #023e8a;
  }
}